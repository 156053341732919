// @flow

import React from "react";
import {
    DefaultLayout,
    Layout,
    withPrivateRoute,
    AdminLabels,
} from "@containers";

/**
 * NetworkInspectionLabels
 */
const NetworkInspectionLabels = (props: *) => (
    <Layout {...props}>
        <DefaultLayout title="Netwerkinspectielabels">
            <AdminLabels id="beheer-labels" />
        </DefaultLayout>
    </Layout>
);

export default withPrivateRoute(NetworkInspectionLabels);
